//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminOrginizations = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [orgDeleted, setOrgDeleted] = useState()
	const [loadedOrgs, setLoadedOrgs] = useState()
	const [orgToDelete, setOrgToDelete] = useState()
	const [orgId, setOrgId] = useState('')

	//First, lets tap into the following route on the backend to get all
	//the organizations
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setOrgDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/allOrgs`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedOrgs(responseData.allOrgs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedOrgs, orgDeleted])
	//
	//
	//
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	//
	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e, orgname) => {
		const value = e.target.id
		setOrgToDelete(orgname)
		setOrgId(loadedOrgs[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('league right here: ' + leagueId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${orgId}/removeOrg`,
				'DELETE'
			)
		} catch (err) {}
		setOrgDeleted(true)
	}
	//
	//
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center'>
						<LoadingSpinner />
					</div>
				)}
				<Modal
					show={showConfirmModal}
					onCancel={cancelDeleteHandler}
					header='Are you sure?'
					footerClass='place-item__modal-actions'
					footer={
						<React.Fragment>
							<p className='modalText'>
								Are you sure you wish to delete <h1>{orgToDelete}</h1>
							</p>
							<br></br>
							<button
								className='cancelDeleteButtons buttonButton'
								inverse
								style={{ float: 'right' }}
								onClick={cancelDeleteHandler}
							>
								CANCEL
							</button>
							<button
								className='cancelDeleteButtons buttonButton'
								style={{ color: 'red' }}
								danger
								onClick={confirmDeleteHandler}
							>
								DELETE ORGANIZATION
							</button>
						</React.Fragment>
					}
				></Modal>
				{!isLoading && (
					<div className='RegLeagues'>
						<AdminNavigation />
						<h1>Organizations</h1>

						<table id='regLeagues'>
							<thead>
								<tr>
									<th>Org Name</th>
									<th>Address</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{loadedOrgs &&
									loadedOrgs.map((org, key) => {
										return (
											<tr key={key}>
												<td>{org.orgName}</td>
												<td>{org.orgAddress}</td>
												<td>
													<NavLink
														className='navlinks'
														to={`/admin/${org._id}/updateOrg`}
														exact
													>
														EDIT
													</NavLink>
												</td>
												<td>
													<button
														className='buttonButton'
														style={{ color: 'red' }}
														id={key}
														onClick={(e) =>
															showDeleteWarningHandler(e, org.orgName)
														}
													>
														DELETE
													</button>
												</td>
											</tr>
										)
									})}
							</tbody>
						</table>
						<div
							style={{ width: '150%', marginTop: '1rem', marginBottom: '4rem' }}
						>
							<AdminButton
								to={`/admin/org/new`}
								exact
								style={{ color: 'white', textDecoration: 'none' }}
							>
								NEW ORGANIZATION
							</AdminButton>
						</div>
					</div>
				)}
			</React.Fragment>
		</div>
	)
}

export default AdminOrginizations
